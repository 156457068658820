import React from "react";
import classNames from 'classnames'

export const HighlightSection = ({ isPrimary, children }) => {
  const sectionClassNames = classNames(
    "text-center lg:text-2xl",
    {
      "bg-primary text-white": isPrimary,
      "text-black": !!isPrimary
    }
  )
  return (
    <section className={sectionClassNames}>
      <div className="container mx-auto px-4 lg:px-0 py-8 lg:py-16">
        {children}
      </div>
    </section>
  );
};

export const Highlight = ({isPrimary, title, subtitle, text, children}) => {
  const titleClassNames = classNames(
    "text-3xl font-semibold",
    {"mb-8 lg:mb-32": !subtitle && !text}
  )
  const subtitleClassNames = classNames(
    "text-base font-light",
    {"mb-8": subtitle && !text}
  )
  const textClassNames = classNames(
    "text-base",
    {"mt-8 lg:mt-16 px-4 lg:px-0": title || subtitle}
  )
  return (
    <HighlightSection isPrimary={isPrimary}>
      {title && <h1 class={titleClassNames}>{title}</h1>}
      {subtitle && <sm className={subtitleClassNames}>{subtitle}</sm>}
      {text && <p className={textClassNames}>{text}</p> }
      {children && <div className="px-4 lg:px-0">{children}</div> }
    </HighlightSection>
  );
};

export const HighlightedImages = ({ images, size }) => {
  const listClassNames = classNames(
    "overflow-hidden grid",
    `grid-cols-${images.length} lg:gap-4`,
    {"h-[138px] lg:h-[25vh]": size === "S"},
    {"h-[138px] lg:h-[505px]": size === "L"},
    {"h-[276px] lg:h-[1000px]": size === "XL"},
  )
  return (
    <section>
      <ul className={listClassNames}>
        {images.map(({ image }) => (
          <li key={image.src}>
            <img
              src={image.src}
              alt={image.image}
              className="w-full h-full object-cover"
            />
          </li>
        ))}
      </ul>
    </section>
  );
};
