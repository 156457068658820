import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { useEnfancePage } from "../services/firestore";
import { Highlight, HighlightedImages } from '../components/Highlight'

const Groups = ({ groups }) => {
  return (
    <Highlight>
      <ul className="text-left grid grid-cols-1 lg:grid-cols-2 gap-4">
        {groups.map(({ title, description }) => (
          <li key={title}>
            <dl className="lg:p-16">
              <dt className="text-lg font-bold">{title}</dt>
              <dd className="text-base">{description}</dd>
            </dl>
          </li>
        ))}
      </ul>
    </Highlight>
  );
};

const EnfancePage = ({ data }) => {
  const page = useEnfancePage(data.allEnfancePages.nodes[0]);

  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Enfance"}>
      {page && (
        <Highlight
          title={page.title}
          subtitle={page.subtitle}
          text={page.highlight}
        ></Highlight>
      )}

      {page && <HighlightedImages size="L" images={page.images} />}

      {page && <Groups groups={page.groups} />}
    </Layout>
  );
};

export const query = graphql`
  query {
    allEnfancePages {
      nodes {
        title
        subtitle
        highlight
        heroBannerType
        buttons {
          text
          url
          subject
          default
        }
        heroBannerImage {
          src
        }
        groups {
          description
          title
        }
        images {
          image {
            image
            src
          }
        }
      }
    }
  }
`;

export default EnfancePage;
